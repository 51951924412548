<template>
  <div>
    <!-- <div v-if="detailData && detailData.dataDetail" class="apartment-condition mt-12 aa">
      <div class="apartment-label">{{ $t("casbee.label_facility") }}</div>
      <div class="facility mb-12">
        <div class="facility-item device">
          <div class="facility-label">{{ $t("casbee.label_heating_equipment") }}</div>
          <div class="label-edit">{{ heatingEquipment }}</div>
        </div>
        <div class="facility-item">
          <div class="facility-label">{{ $t("casbee.label_cooling_equipment") }}</div>
          <div class="label-edit">{{ coolingDevice }}</div>
        </div>
      </div>

      <div class="options mb-12">
        <div class="options-item">
          <div class="options-label">BEI</div>
          <div class="label-edit">{{ bels[getBelIndex].name }}</div>
        </div>
        <div class="options-item">
          <div class="options-label">{{ $t("casbee.label_area_division") }}</div>
          <div class="label-edit">{{ facility }}</div>
        </div>
      </div>

      <div class="emission-factor mb-12">
        <div class="options-label">{{ $t("casbee.label_electricity_emission") }}</div>
        <div class="options-description">{{ $t("casbee.description_change_emission_factor") }}</div>
        <div class="options-input">
          <div class="label-edit">{{ factor }}</div>
          <div class="options-input-unit">t-CO2/kWh</div>
        </div>
      </div>

      <common-button
        class="casbee-btn"
        v-ripple="false"
        type="colored"
        :label="$t('casbee.button_calc_value_inputed')"
        @action="handlerCalculationData()"
      />
    </div> -->

    <div class="apartment-condition mt-12">
      <div class="apartment-label">{{ $t("casbee.label_facility") }}</div>
      <div class="facility mb-12">
        <div class="facility-item device">
          <div class="facility-label">{{ $t("casbee.label_heating_equipment") }}</div>
          <default-pull-down-menu
            v-model="heatingEquipment"
            :items="heatingEquipments"
            :initialValue="heatingEquipment"
            :width="pullDownWidth"
            :boxWidth="pullDownWidth"
            :height="heightPullDown"
            :isCasbeePulldown="true"
            :disabled="isDisable"
            class="casbee-pulldown"
          />
        </div>
        <div class="facility-item">
          <div class="facility-label">{{ $t("casbee.label_cooling_equipment") }}</div>
          <default-pull-down-menu
            v-model="coolingDevice"
            :items="coolingDevices"
            :initialValue="coolingDevice"
            :width="pullDownWidth"
            :boxWidth="pullDownWidth"
            :height="heightPullDown"
            :isCasbeePulldown="true"
            :disabled="isDisable"
            class="casbee-pulldown"
          />
        </div>
      </div>

      <div class="options mb-12">
        <div class="options-item">
          <div class="options-label">BEI</div>
          <default-pull-down-menu
            v-model="bel"
            :items="bels"
            :initialValue="bel"
            :width="'128px'"
            :boxWidth="'128px'"
            :height="heightPullDown"
            :isCasbeePulldown="true"
            :disabled="isDisable"
            class="casbee-pulldown"
          />
        </div>
        <div class="options-item">
          <div class="options-label">{{ $t("casbee.label_area_division") }}</div>
          <default-pull-down-menu
            v-model="facility"
            :items="facilitys"
            :initialValue="facility"
            :width="'80px'"
            :boxWidth="'80px'"
            :height="heightPullDown"
            :isCasbeePulldown="true"
            :disabled="isDisable"
            class="casbee-pulldown"
          />
        </div>
      </div>

      <div class="emission-factor mb-12">
        <div class="options-label">{{ $t("casbee.label_electricity_emission") }}</div>
        <div class="options-description">{{ $t("casbee.description_change_emission_factor") }}</div>
        <div class="options-input">
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="factor"
            :value="factor"
            :label="'t-CO2/kWh'"
            width="95"
            :disabled="isDisable"
            :errorMessage="inputErrors.factor"
            @removeError="inputErrors.factor = ''"
          />
        </div>
      </div>

      <common-button
        v-if="!isDisable"
        class="casbee-btn"
        v-ripple="false"
        type="colored"
        :label="$t('casbee.button_calc_value_inputed')"
        :isDisable="isDisable"
        @action="handlerCalculationData()"
      />
    </div>
  </div>
</template>
<script>
import CommonButton from '@/components/utils/button.vue';
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import CasbeeInput from '@/components/casbee/input';
import {
  FACILITY_LIST,
  BELS,
  HEATING_EQUIPMENTS,
  COOLING_DEVICES,
  get_emission_factor,
  emissionFor1m2,
  emissionForHourUnit,
  emissionContinuousOperation,
} from '@/constants/casbee/apartment-conditional-input';
import { CASBEE_TYPE } from '@/constants/casbee/';
import { validateInputFieldCasbee } from '@/utils/validate';
import { floor } from 'mathjs';
import {getWidthByTextContent} from '@/utils/calcTextWidth'

export default {
  components: { CommonButton, DefaultPullDownMenu, CasbeeInput },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      calcData: {
        total: null,
        source: '',
      },
      heatingEquipments: [],
      heatingEquipment: null,
      coolingDevices: [],
      coolingDevice: null,
      bels: [],
      bel: null,
      facility: null,
      facilitys: [],
      factor: 0.00043,
      factorError: false,
      label: this.$t('casbee.label_owned_area'),
      labelInside: this.$t('casbee.label_outdoor_corridor'),
      labelOutside: this.$t('casbee.label_indoor_corridor'),
      labelSource: this.$t("casbee.description_source"),
      validForm: true,
      inputErrors: {
        factor: '',
      },
      resizeTimer: null,
      pullDownWidth: '320px',
      heightPullDown: '32px',
      belIndex: 0
    };
  },
  mounted() {
    this.heatingEquipments = [
      {
        value: HEATING_EQUIPMENTS.HOUSE_UNIT_HEATING,
        name: HEATING_EQUIPMENTS.HOUSE_UNIT_HEATING,
      },
      {
        value: HEATING_EQUIPMENTS.CONTINUOUS_OPERATION,
        name: HEATING_EQUIPMENTS.CONTINUOUS_OPERATION,
      },
      {
        value: HEATING_EQUIPMENTS.INTERMITTENT_OPERATION,
        name: HEATING_EQUIPMENTS.INTERMITTENT_OPERATION,
      },
    ];
    this.heatingEquipment = HEATING_EQUIPMENTS.HOUSE_UNIT_HEATING;

    this.coolingDevices = [
      {
        value: COOLING_DEVICES.HOUSE_UNIT_HEATING,
        name: COOLING_DEVICES.HOUSE_UNIT_HEATING,
      },
      {
        value: COOLING_DEVICES.INTERMITTENT_OPERATION,
        name: COOLING_DEVICES.INTERMITTENT_OPERATION,
      },
    ];

    this.coolingDevice = COOLING_DEVICES.HOUSE_UNIT_HEATING;
    this.facility = FACILITY_LIST[0];
    this.facilitys = FACILITY_LIST.map((item) => {
      return {
        value: item,
        name: item,
      };
    });
    this.bels = [
      {
        value: 1.2,
        name: BELS.BEL_HEIGHER,
      },
      {
        value: 1,
        name: BELS.BEL,
      },
    ];
    this.bel = 1;
    this.prepareDetailData();
    this.pullDownWidth = this.getWidth();
    this.heightPullDown = this.getWidth() === '320px' ? '32px' : '32px';
    this.onWindowResize();
  },
  methods: {
    getDevice(heatingEquipment, deviceCooling) {
      let equipment = '';
      switch (heatingEquipment) {
        case HEATING_EQUIPMENTS.HOUSE_UNIT_HEATING:
          equipment = 'A';
          break;
        case HEATING_EQUIPMENTS.CONTINUOUS_OPERATION:
          equipment = 'B';
          break;
        case HEATING_EQUIPMENTS.INTERMITTENT_OPERATION:
          equipment = 'C';
          break;
        default:
          break;
      }

      if (deviceCooling === COOLING_DEVICES.HOUSE_UNIT_HEATING) {
        return equipment + 'a';
      } else {
        return equipment + 'b';
      }
    },
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });
      if(!this.isValidData) {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
        return
      }

      const device = this.getDevice(this.heatingEquipment, this.coolingDevice);
      const emission_factor = get_emission_factor(device, this.bel, this.facility);
      const emissionsIn1m2 = floor(emissionFor1m2(emission_factor, this.factor),4);
      const emissionsForHourUnit = floor(emissionForHourUnit(this.factor, this.bel),4);
      const emissionsContinuousOperation = floor(emissionContinuousOperation(this.factor, this.bel),4);
      const belIndex = this.bels.findIndex((bel) => bel.value === this.bel);
      const heatingEquipment = this.heatingEquipment;
      const coolingDevice = this.coolingDevice;
      const bei = this.bels[belIndex].name;
      const facility = this.facility;
      const source = this.$t('casbee.input_field_source_text_architecture_choose_condition', {heatingEquipment, coolingDevice, bei, facility});
      const sourceInside = source;
      const sourceOutside = source;
      this.calcData = {
        total: emissionsIn1m2,
        totalInside: emissionsForHourUnit,
        totalOutside: emissionsContinuousOperation,
        source: source,
        sourceInside: sourceInside,
        sourceOutside: sourceOutside,
        label: this.label,
        labelInside: this.labelInside,
        labelOutside: this.labelOutside,
        labelSource: this.labelSource,
        patternType: CASBEE_TYPE.ARCHITECTURE_APARTMENT_CHOOSE_CONDITION,
        content_json: {
          coolingDevice: this.coolingDevice,
          facility: this.facility,
          bel: this.bel,
          factor: this.factor,
          heatingEquipment: this.heatingEquipment,
          factor: this.factor,
        }
      };
      this.$emit('onHandlerCalculationData', this.calcData);
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
    getWidth() {
      if (window.innerWidth >= 1024) {
        return this.widthPulldownByText(this.heatingEquipments, '320px');
      } else if (window.innerWidth >= 375) {
        return '100%';
      }
      return '100%';
    },
    widthPulldownByText(dataList, widthJa) {
      if(this.$i18n.locale === 'ja') {
        return widthJa;
      }
      let widthListPulldowns = [];
      dataList.forEach(item => {
        widthListPulldowns.push(getWidthByTextContent(item.name));
      });
      return Math.max(...widthListPulldowns) + 'px';
    },
    onWindowResize() {
      window.addEventListener('resize', this.handleWindowResize);
    },
    handleWindowResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.pullDownWidth = this.getWidth();
        this.heightPullDown = this.getWidth() === '320px' ? '32px' : '32px';
      }, 100);
    },
  },
  computed: {
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    getBelIndex() {
      return this.bels.findIndex((bel) => bel.value === this.detailData?.dataDetail?.content_json?.bel);
    },
    isDisable() {
      return this.detailData?.success
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-input__control {
  min-height: 32px !important;
  width: 80px;
}
::v-deep .v-input__slot {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 0px 8px;
  height: 32px;
  background: $monoWhite;
  border: 1px solid #999999;
  border-radius: 6px;
}
::v-deep .v-text-field__slot input{
  text-align: end;
}
.apartment-condition {
  .apartment-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin-bottom: 8px;
  }
  .facility {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    .facility-item {
      .facility-label {
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        color: $monoDark;
        letter-spacing: 0.05em;
        margin-bottom: 8px;
      }
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 48px;
    .options-item {
      .options-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        margin-bottom: 8px;
      }
    }
  }
  .emission-factor {
    .options-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;
    }
    .options-description {
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      color: $monoBlack;
      letter-spacing: 0.05em;
      margin-bottom: 8px;
    }
    .options-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-items: baseline;
      padding: 0px;
      gap: 8px;
      // width: 159px;
      .input-elic {
        width: 80px;
        height: 32px;
        padding: 0 8px;
        text-align: right;
        &.has-error {
          border: 1px solid $redMid;
        }
      }
      .icon-error {
        position: absolute;
        left: 100px;
        margin-top: 8px;
      }
      &-unit {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $monoBlack;
        min-width: 100px;
        letter-spacing: 0.03em;
      }
    }
  }
  .casbee-btn {
    width: 100%;
  }
  ::v-deep .pull-down-adjust .selection-box-bridge .input-box {
    width: calc(100vw - 50px);
  }
}

.label-edit {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.05em;
  color: $monoBlack;
}

@include desktop {
  .apartment-condition {
    .apartment-label {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      margin-bottom: 12px;
    }
    .facility {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 20px;
      .facility-item {
        .facility-label {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: $monoDark;
          margin-bottom: 12px;
        }
      }
    }
    .options {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 8px;
      gap: 40px;
      .options-item {
        .options-label {
          font-weight: 400;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0.05em;
          color: $monoBlack;
          margin-bottom: 12px;
        }
      }
    }
    .emission-factor {
      .options-label {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.05em;
        color: $monoBlack;
        margin-bottom: 12px;
      }
      .options-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $monoBlack;
        margin-bottom: 12px;
      }
      .options-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-items: baseline;
        padding: 0px;
        gap: 8px;
        width: 100%;
        .input-elic {
          width: 80px;
          height: 32px;
          padding: 0 8px;
          text-align: right;
          &.has-error {
            border: 1px solid $redMid;
          }
        }
        .icon-error {
          position: absolute;
          left: 100px;
          margin-top: 8px;
        }
        &-unit {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $monoBlack;
          font-family: $notoSanFont;
          min-width: 100px;
        }
      }
    }
    .casbee-btn {
      width: max-content;
    }
  }
}
</style>